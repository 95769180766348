import * as React from "react"
import DownloadFilePage from "@/components/DownloadFilePage"
import { useGTMInit} from '@/hooks/useGTM.js'



const Index = () => {
  React.useEffect(()=>{
    useGTMInit('support','Nreal_Light_After_Sales_&_Warranty_US_pdf')
  },[])
  return(
    <>
    <DownloadFilePage
      downloadURL={`${process.env.resourceUrl}docs/manual/Nreal_Light_After_Sales_&_Warranty_US.pdf`}
    />
  </>
  )
}
  
  

export default Index